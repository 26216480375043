import { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { Link } from 'react-router-dom';
import AvenidaTrans from '../assets/AvenidaTrans.png';

interface LandingPageProps {
  onEnter: () => void;
  isAdmin?: boolean;
}

const LandingPage = ({ onEnter, isAdmin = false }: LandingPageProps) => {
  const [activePanel, setActivePanel] = useState<'none' | 'menu' | 'vision' | 'contact' | 'privacy'>('none');
  const [textVisible, setTextVisible] = useState(true);

  useEffect(() => {
    if (activePanel !== 'none') {
      setTextVisible(false);
    } else {
      const timer = setTimeout(() => {
        setTextVisible(true);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [activePanel]);

  const handleHomeClick = () => {
    setActivePanel('none');
  };

  return (
    <div className="w-full min-h-screen bg-[#F0EFE9]">
      {/* Mobile Navigation Panel */}
      <div 
        className={`fixed inset-0 bg-red-900 text-white transform transition-transform duration-300 ease-in-out ${
          activePanel === 'menu' ? 'translate-x-0' : 'translate-x-full'
        } z-50 md:hidden`}
      >
        <div className="p-8">
          <div className="flex justify-between items-center">
            <div className="h-12">
              <img src={AvenidaTrans} alt="Avenida" className="h-full w-auto object-contain brightness-0 invert" />
            </div>
            <button onClick={() => setActivePanel('none')} className="p-2" aria-label="Close menu">
              <X className="w-6 h-6" />
            </button>
          </div>
          
          <div className="flex flex-col items-center justify-center h-[80vh] space-y-8">
            <button onClick={handleHomeClick} className="text-lg font-light tracking-wide hover:opacity-80">
              Home
            </button>
            <button onClick={() => setActivePanel('vision')} className="text-lg font-light tracking-wide hover:opacity-80">
              Vision
            </button>
            <button onClick={() => setActivePanel('contact')} className="text-lg font-light tracking-wide hover:opacity-80">
              Contact us
            </button>
            <button onClick={onEnter} className="text-lg font-light tracking-wide hover:opacity-80">
              Apply for membership
            </button>
            {/* {isAdmin && (
              <Link to="/admin" className="text-lg font-light tracking-wide hover:opacity-80">
                Admin
              </Link>
            )} */}
          </div>
        </div>
      </div>

      {/* Vision Panel */}
      <div 
        className={`fixed top-0 bottom-0 right-0 w-full md:w-1/2 bg-[#F7F5EF] transform transition-transform duration-300 ease-in-out ${
          activePanel === 'vision' ? 'translate-x-0' : 'translate-x-full'
        } z-50 overflow-y-auto`}
      >
        <div className="p-8">
          <div className="flex justify-between items-center mb-12">
            <div className="h-12 md:hidden">
              <img src={AvenidaTrans} alt="Avenida" className="h-full w-auto object-contain" />
            </div>
            <div className="ml-auto">
              <button onClick={() => setActivePanel('none')} className="p-2" aria-label="Close vision">
                <X className="w-6 h-6" />
              </button>
            </div>
          </div>

          <div className="mt-12 space-y-8 max-w-xl">
            <h2 className="text-3xl font-serif">Our vision</h2>
            <p className="text-gray-800 leading-relaxed font-light">
              Born from the vibrant and elegant spirit of Avenida da Liberdade...
            </p>
            <p className="text-gray-800 leading-relaxed font-light">
              Avenida connects discerning individuals with intimate, curated experiences worldwide. We've created a global community where every event feels like being at your best friend's home - warm, welcoming, and filled with fascinating people.
            </p>
            <p className="text-gray-800 leading-relaxed font-light">
              Our mission is clear: to ensure that wherever you are in the world, you're only a tap away from events that truly speak to you. With Avenida, you're always in the right place, with the right people.
            </p>
            <p className="text-gray-800 leading-relaxed font-light">
              We're more than a platform; we're a passport to the world's most sought-after and authentic local experiences. Whether you're hosting an intimate soirée or seeking an exquisite event abroad, Avenida ensures you're always in good company.
            </p>
          </div>
        </div>
      </div>

      {/* Contact Panel */}
      <div 
        className={`fixed top-0 bottom-0 right-0 w-full md:w-1/2 bg-[#F7F5EF] transform transition-transform duration-300 ease-in-out ${
          activePanel === 'contact' ? 'translate-x-0' : 'translate-x-full'
        } z-50 overflow-y-auto`}
      >
        <div className="p-8">
          <div className="flex justify-between items-center mb-12">
            <div className="h-12 md:hidden">
              <img src={AvenidaTrans} alt="Avenida" className="h-full w-auto object-contain" />
            </div>
            <div className="ml-auto">
              <button onClick={() => setActivePanel('none')} className="p-2" aria-label="Close contact">
                <X className="w-6 h-6" />
              </button>
            </div>
          </div>

          <div className="mt-12 space-y-12 max-w-xl">
            <h2 className="text-3xl font-serif">Get in touch</h2>
            <div className="space-y-8">
              <div>
                <h3 className="text-xl font-serif mb-2">General</h3>
                <a href="mailto:info@avenida.app" className="text-gray-800 hover:text-gray-900 font-light">
                  info@avenida.app
                </a>
              </div>
              <div>
                <h3 className="text-xl font-serif mb-2">Membership</h3>
                <a href="mailto:membership@avenida.app" className="text-gray-800 hover:text-gray-900 font-light">
                  membership@avenida.app
                </a>
              </div>
              <div>
                <h3 className="text-xl font-serif mb-2">Press</h3>
                <a href="mailto:press@avenida.app" className="text-gray-800 hover:text-gray-900 font-light">
                  press@avenida.app
                </a>
              </div>
              <div>
                <h3 className="text-xl font-serif mb-2">Partnership</h3>
                <a href="mailto:partnership@avenida.app" className="text-gray-800 hover:text-gray-900 font-light">
                  partnership@avenida.app
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Privacy Policy Panel */}
      <div 
        className={`fixed top-0 bottom-0 right-0 w-full md:w-1/2 bg-[#F7F5EF] transform transition-transform duration-300 ease-in-out ${
          activePanel === 'privacy' ? 'translate-x-0' : 'translate-x-full'
        } z-50 overflow-y-auto`}
      >
        <div className="p-8">
          <div className="flex justify-between items-center mb-12">
            <div className="h-12 md:hidden">
              <img src={AvenidaTrans} alt="Avenida" className="h-full w-auto object-contain" />
            </div>
            <div className="ml-auto">
              <button onClick={() => setActivePanel('none')} className="p-2" aria-label="Close privacy">
                <X className="w-6 h-6" />
              </button>
            </div>
          </div>

          <div className="mt-12 space-y-6 max-w-xl text-gray-800">
            <h2 className="text-3xl font-serif mb-8">Privacy Policy</h2>
             
            <section>
              <h3 className="text-xl font-serif mb-4">1. Information We Collect</h3>
              <p className="font-light">We collect information you provide directly to us when you:</p>
              <ul className="list-disc pl-5 mt-2 space-y-2 font-light">
                <li>Create an account or profile</li>
                <li>Register for events</li>
                <li>Contact us for support</li>
                <li>Complete forms or surveys</li>
              </ul>
            </section>

            <section>
              <h3 className="text-xl font-serif mb-4">2. How We Use Your Information</h3>
              <p className="font-light">We use collected information to:</p>
              <ul className="list-disc pl-5 mt-2 space-y-2 font-light">
                <li>Provide and improve our services</li>
                <li>Process transactions and bookings</li>
                <li>Send notifications about events</li>
                <li>Personalize your experience</li>
                <li>Ensure platform security</li>
              </ul>
            </section>

            <section>
              <h3 className="text-xl font-serif mb-4">3. Information Sharing</h3>
              <p className="font-light">We may share information with:</p>
              <ul className="list-disc pl-5 mt-2 space-y-2 font-light">
                <li>Event hosts (limited to necessary details)</li>
                <li>Service providers and partners</li>
                <li>Legal authorities when required</li>
              </ul>
            </section>

            <section>
              <h3 className="text-xl font-serif mb-4">4. Contact</h3>
              <p className="font-light">For privacy inquiries:</p>
              <a href="mailto:privacy@avenida.app" className="text-red-900 hover:text-red-800 font-light">
                privacy@avenida.app
              </a>
            </section>

            <section>
              <p className="font-light mt-8">Last updated: December 2024</p>
            </section>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="min-h-screen flex flex-col">
        {/* Navigation */}
        <nav className="w-full px-4 sm:px-8 py-6">
          <div className="flex justify-between items-center">
            <div className="h-12">
              <img src={AvenidaTrans} alt="Avenida" className="h-full w-auto object-contain" />
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex gap-12">
              <button 
                onClick={() => setActivePanel('contact')}
                className="relative font-light tracking-wide hover:text-gray-600 group"
              >
                Contact Us
                <span className="absolute bottom-0 left-0 w-full h-0.5 bg-red-900 transform scale-x-0 transition-transform group-hover:scale-x-100"></span>
                {activePanel === 'contact' && (
                  <span className="absolute bottom-0 left-0 w-full h-0.5 bg-red-900"></span>
                )}
              </button>
              <button 
                onClick={() => setActivePanel('vision')}
                className="relative font-light tracking-wide hover:text-gray-600 group"
              >
                Vision 
                <span className="absolute bottom-0 left-0 w-full h-0.5 bg-red-900 transform scale-x-0 transition-transform group-hover:scale-x-100"></span>
                {activePanel === 'vision' && (
                  <span className="absolute bottom-0 left-0 w-full h-0.5 bg-red-900"></span>
                )}
              </button>
              <button 
                onClick={onEnter}
                className="font-light tracking-wide hover:text-gray-600"
              >
                Apply for membership
              </button>
              {/* {isAdmin && (
                <Link to="/admin" className="font-light tracking-wide hover:text-gray-600">
                  Admin
                </Link>
              )} */}
            </div>

            {/* Mobile Menu Button */}
            <button 
              onClick={() => setActivePanel('menu')}
              className="md:hidden p-2"
              aria-label="Toggle menu"
            >
              <svg 
                className="w-6 h-6" 
                fill="none" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </nav>

        {/* Hero Section */}
        <div className="flex-1 flex flex-col items-center justify-center px-4 md:px-6">
          <div className="text-center space-y-5">
            <h1 
              className={`text-[24px] font-serif text-black transition-opacity duration-300 ease-in-out ${
                textVisible ? 'opacity-100' : 'opacity-0'
              }`}
            >
              Bespoke Gatherings
            </h1>
            
            <p 
              className={`text-[20px] font-serif text-black transition-opacity duration-300 ease-in-out ${
                textVisible ? 'opacity-100' : 'opacity-0'
              }`}
            >
              Invitation Only
            </p>
          </div>
        </div>

        {/* Footer Links */}
        <div className="w-full text-center pb-4 flex justify-center space-x-8">
          <button 
            onClick={() => setActivePanel('privacy')}
            className="text-sm text-gray-600 hover:text-gray-800 font-light"
          >
            Privacy Policy
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
