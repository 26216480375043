import React, { useState } from "react";
import { ChevronLeft, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { createClient } from "@supabase/supabase-js";
import AvenidaTrans from '../assets/AvenidaTrans.png';

interface PersonalInfo {
  firstName: string;
  lastName: string;
  identity: string;
  dob: string;
  email: string;
  location: string;
}

interface AdditionalInfo {
  jobTitle: string;
  interests: string[];
  otherClubs: string;
  clubNames?: string;
  instagramHandle: string;
  reference: string;
  contribution: string;
}

interface PaymentInfo {
  cardNumber: string;
  expiryDate: string;
  cvv: string;
  country: string;
  billingAddress: string;
  city: string;
  postalCode: string;
}

type FormData = {
  personalInfo: PersonalInfo;
  additionalInfo: AdditionalInfo;
  paymentInfo: PaymentInfo;
};

interface MembershipFormProps {
  onClose: () => void;
  onSubmit: () => void;
}

type PaymentPlan = 'monthly' | 'yearly';

const INITIAL_FORM_DATA: FormData = {
  personalInfo: {
    firstName: "",
    lastName: "",
    identity: "",
    dob: "",
    email: "",
    location: "",
  },
  additionalInfo: {
    jobTitle: "",
    interests: [],
    otherClubs: "",
    instagramHandle: "",
    reference: "",
    contribution: "",
  },
  paymentInfo: {
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    country: "",
    billingAddress: "",
    city: "",
    postalCode: "",
  },
};

const InterestPill = ({
  interest,
  onRemove,
}: {
  interest: string;
  onRemove: () => void;
}) => (
  <div className="inline-flex items-center gap-1 px-3 py-1 bg-gray-100 rounded-full text-sm">
    {interest}
    <button onClick={onRemove} type="button" aria-label="Remove interest">
      <X className="w-3 h-3" />
    </button>
  </div>
);

const MembershipForm: React.FC<MembershipFormProps> = ({
  onClose,
  onSubmit,
}) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<FormData>(INITIAL_FORM_DATA);
  const [currentInterest, setCurrentInterest] = useState("");
  const [subscribeToUpdates, setSubscribeToUpdates] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hCaptchaToken, setHCaptchaToken] = useState<string | null>(null);
  const [paymentPlan, setPaymentPlan] = useState<PaymentPlan>('monthly');
  const navigate = useNavigate();

  const getSupabaseClientWithCaptcha = (captchaToken: string) => {
    return createClient(
      import.meta.env.VITE_SUPABASE_URL,
      import.meta.env.VITE_SUPABASE_ANON_KEY,
      {
        global: {
          headers: {
            "x-hcaptcha-token": captchaToken,
          },
        },
      }
    );
  };
  
  const handleCaptchaVerification = (token: string) => {
    setHCaptchaToken(token);
  };

  const validateForm = (): string | null => {
    const { personalInfo, additionalInfo } = formData;

    if (!personalInfo.firstName.trim()) return "First name is required.";
    if (!personalInfo.lastName.trim()) return "Last name is required.";
    if (!personalInfo.email.trim() || !/\S+@\S+\.\S+/.test(personalInfo.email))
      return "A valid email is required.";
    if (!personalInfo.dob) return "Date of birth is required.";
    if (!additionalInfo.jobTitle.trim()) return "Job title is required.";
    if (!additionalInfo.contribution.trim())
      return "Contribution field is required.";

    return null;
  };

  const handleSubmit = async () => {
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }
  
    if (!hCaptchaToken) {
      setError("Please complete the hCaptcha verification.");
      return;
    }
  
    setIsSubmitting(true);
    setError(null);
  
    try {
      const supabaseWithCaptcha = getSupabaseClientWithCaptcha(hCaptchaToken);
  
      const { error } = await supabaseWithCaptcha
        .from("membership_applications")
        .insert({
          first_name: formData.personalInfo.firstName,
          last_name: formData.personalInfo.lastName,
          identity: formData.personalInfo.identity || null,
          dob: formData.personalInfo.dob || null,
          email: formData.personalInfo.email,
          location: formData.personalInfo.location || null,
          job_title: formData.additionalInfo.jobTitle || null,
          interests: formData.additionalInfo.interests.length
            ? formData.additionalInfo.interests
            : null,
          other_clubs: formData.additionalInfo.otherClubs || null,
          club_names: formData.additionalInfo.clubNames || null,
          instagram_handle: formData.additionalInfo.instagramHandle || null,
          contribution: formData.additionalInfo.contribution || null,
          country: formData.paymentInfo.country || null,
          billing_address: formData.paymentInfo.billingAddress || null,
          city: formData.paymentInfo.city || null,
          postal_code: formData.paymentInfo.postalCode || null,
          subscribed_to_updates: subscribeToUpdates,
        });
  
      if (error) {
        throw error;
      }
  
      console.log("Application submitted successfully");
      setFormData(INITIAL_FORM_DATA);
      setHCaptchaToken(null);
      onSubmit();
      navigate("/thank-you");
    } catch (err) {
      console.error("Error inserting application:", err);
      setError("Failed to submit the application. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const addInterest = (interest: string) => {
    if (
      interest.trim() &&
      !formData.additionalInfo.interests.includes(interest.trim())
    ) {
      updateAdditionalInfo("interests", [
        ...formData.additionalInfo.interests,
        interest.trim(),
      ]);
      setCurrentInterest("");
    }
  };

  const removeInterest = (indexToRemove: number) => {
    updateAdditionalInfo(
      "interests",
      formData.additionalInfo.interests.filter(
        (_, index) => index !== indexToRemove
      )
    );
  };

  const updatePersonalInfo = (field: keyof PersonalInfo, value: string) => {
    setFormData((prev) => ({
      ...prev,
      personalInfo: { ...prev.personalInfo, [field]: value },
    }));
  };

  const updateAdditionalInfo = (
    field: keyof AdditionalInfo,
    value: string | string[]
  ) => {
    setFormData((prev) => ({
      ...prev,
      additionalInfo: { ...prev.additionalInfo, [field]: value },
    }));
  };

  const updatePaymentInfo = (field: keyof PaymentInfo, value: string) => {
    setFormData((prev) => ({
      ...prev,
      paymentInfo: { ...prev.paymentInfo, [field]: value },
    }));
  };

  const handleContinue = () => {
    if (step < 5) setStep(step + 1);
    else handleSubmit();
  };

  const handleBack = () => {
    if (step > 1) setStep(step - 1);
    else onClose();
  };

  return (
    <div className="fixed inset-0 bg-[#F0EFE9] z-50 overflow-y-auto">
      <div className="max-w-2xl mx-auto px-6 py-8">
        <div className="flex justify-between items-center mb-12">
          <div className="h-12">
            <img src={AvenidaTrans} alt="Avenida" className="h-full w-auto object-contain" />
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Close"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="mb-24">
          {step === 1 && (
            <div className="space-y-8 mt-96">
              <h1 className="text-4xl font-serif text-center">Membership</h1>
              <div className="space-y-6 max-w-md mx-auto">
                <p className="text-sm text-center text-gray-600">
                  Please fill out all the fields carefully in order for this
                  application to be submitted to the Committee.
                </p>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="space-y-6">
              <h1 className="text-4xl font-serif">Getting to know you</h1>
              <div className="space-y-4">
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder="First name"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.personalInfo.firstName}
                  onChange={(e) => updatePersonalInfo("firstName", e.target.value)}
                />
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder="Last name"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.personalInfo.lastName}
                  onChange={(e) => updatePersonalInfo("lastName", e.target.value)}
                />
                <select
                  id="identity"
                  name="identity"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white text-gray-600"
                  value={formData.personalInfo.identity}
                  onChange={(e) => updatePersonalInfo("identity", e.target.value)}
                >
                  <option value="" className="text-gray-600">How do you identify?</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                <div className="space-y-1">
                  <label htmlFor="dob" className="block text-sm text-gray-600">
                    Date of Birth
                  </label>
                  <input
                    id="dob"
                    name="dob"
                    type="date"
                    aria-label="Date of Birth"
                    className="w-full p-3 border border-gray-200 rounded-md bg-white"
                    value={formData.personalInfo.dob}
                    onChange={(e) => updatePersonalInfo("dob", e.target.value)}
                  />
                </div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.personalInfo.email}
                  onChange={(e) => updatePersonalInfo("email", e.target.value)}
                />
                <input
                  id="location"
                  name="location"
                  type="text"
                  placeholder="Location"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.personalInfo.location}
                  onChange={(e) => updatePersonalInfo("location", e.target.value)}
                />
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="space-y-6">
              <h1 className="text-4xl font-serif">Getting to know you</h1>
              <div className="space-y-4">
                <input
                  id="jobTitle"
                  name="jobTitle"
                  type="text"
                  placeholder="Job role / title"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.additionalInfo.jobTitle}
                  onChange={(e) =>
                    updateAdditionalInfo("jobTitle", e.target.value)
                  }
                />
                <div className="space-y-2">
                  <div className="flex flex-wrap gap-2">
                    {formData.additionalInfo.interests.map(
                      (interest, index) => (
                        <InterestPill
                          key={index}
                          interest={interest}
                          onRemove={() => removeInterest(index)}
                        />
                      )
                    )}
                  </div>
                  <input
                    id="interests"
                    name="interests"
                    type="text"
                    placeholder="Interests"
                    className="w-full p-3 border border-gray-200 rounded-md bg-white"
                    value={currentInterest}
                    onChange={(e) => setCurrentInterest(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        addInterest(currentInterest);
                      }
                    }}
                  />
                </div>

                <div className="space-y-3">
                  <select
                    id="otherClubs"
                    name="otherClubs"
                    className="w-full p-3 border border-gray-200 rounded-md bg-white"
                    value={formData.additionalInfo.otherClubs}
                    onChange={(e) =>
                      updateAdditionalInfo("otherClubs", e.target.value)
                    }
                  >
                    <option value="">
                      Are you a member of any other private clubs?
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>

                  {formData.additionalInfo.otherClubs === "yes" && (
                    <input
                      id="clubNames"
                      name="clubNames"
                      type="text"
                      placeholder="Which private clubs are you a member of?"
                      className="w-full p-3 border border-gray-200 rounded-md bg-white"
                      value={formData.additionalInfo.clubNames || ""}
                      onChange={(e) =>
                        updateAdditionalInfo("clubNames", e.target.value)
                      }
                    />
                  )}
                </div>

                <input
                  id="instagramHandle"
                  name="instagramHandle"
                  type="text"
                  placeholder="What is your Instagram handle?"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.additionalInfo.instagramHandle}
                  onChange={(e) =>
                    updateAdditionalInfo("instagramHandle", e.target.value)
                  }
                />
                <textarea
                  id="contribution"
                  name="contribution"
                  placeholder="Finally, what do you bring to Avenida?"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white h-32 resize-none"
                  value={formData.additionalInfo.contribution}
                  onChange={(e) =>
                    updateAdditionalInfo("contribution", e.target.value)
                  }
                />
              </div>
            </div>
          )}

          {step === 4 && (
            <div className="space-y-6">
              <h1 className="text-4xl font-serif">Payment Details</h1>
              <div className="space-y-4 mb-8">
                <h2 className="font-serif text-xl mb-4">Membership Fee Summary</h2>
                
                <div className="grid grid-cols-2 gap-4 mb-6">
                  <button
                    type="button"
                    onClick={() => setPaymentPlan('monthly')}
                    className={`p-4 rounded-lg border-2 transition-all ${
                      paymentPlan === 'monthly'
                        ? 'border-black bg-black text-white'
                        : 'border-gray-200 hover:border-gray-300'
                    }`}
                  >
                    <div className="text-lg font-medium mb-1">Monthly</div>
                    <div className="text-sm opacity-80">$20/month</div>
                  </button>
                  <button
                    type="button"
                    onClick={() => setPaymentPlan('yearly')}
                    className={`p-4 rounded-lg border-2 transition-all ${
                      paymentPlan === 'yearly'
                        ? 'border-black bg-black text-white'
                        : 'border-gray-200 hover:border-gray-300'
                    }`}
                  >
                    <div className="text-lg font-medium mb-1">Yearly</div>
                    <div className="text-sm opacity-80">$200/year</div>
                  </button>
                </div>
                <div className="text-center text-sm text-gray-500 -mt-3 mb-6">
                  <span className="inline-flex items-center gap-1.5">
                    <svg 
                      className={`w-4 h-4 transition-colors duration-200 ${
                        paymentPlan === 'yearly' ? 'text-green-600' : 'text-gray-500'
                      }`} 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path 
                        d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                      />
                    </svg>
                    Save 17% with annual membership
                  </span>
                </div>

                <div className="space-y-4">
                  <div className="flex justify-between items-center border-b border-gray-200 pb-3">
                    <span className="text-gray-600">
                      {paymentPlan === 'monthly' ? 'Monthly payment' : 'Annual payment'}
                    </span>
                    <span className="font-medium">
                      ${paymentPlan === 'monthly' ? '20' : '200'}
                    </span>
                  </div>
                  
                  <div className="bg-white p-4 rounded-lg border border-gray-200">
                    <h3 className="font-medium mb-3">Membership Benefits</h3>
                    <ul className="space-y-2 text-sm text-gray-600">
                      <li className="flex items-start">
                        <span className="text-black mr-2">•</span>
                        Access to exclusive events and experiences
                      </li>
                      <li className="flex items-start">
                        <span className="text-black mr-2">•</span>
                        Ability to host and curate your own events
                      </li>
                      <li className="flex items-start">
                        <span className="text-black mr-2">•</span>
                        Ability to join groups and recurring events
                      </li>
                      <li className="flex items-start">
                        <span className="text-black mr-2">•</span>
                        Access to special collaborations and partnerships
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="space-y-4">
                <p className="text-sm text-gray-600">
                  Please enter your payment details below. Your card will be securely stored for membership payments.
                </p>
                <input
                  id="cardNumber"
                  name="cardNumber"
                  type="text"
                  placeholder="Card Number"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.paymentInfo.cardNumber}
                  onChange={(e) => updatePaymentInfo("cardNumber", e.target.value)}
                />
                <div className="grid grid-cols-2 gap-4">
                  <input
                    id="expiryDate"
                    name="expiryDate"
                    type="text"
                    placeholder="MM/YY"
                    className="w-full p-3 border border-gray-200 rounded-md bg-white"
                    value={formData.paymentInfo.expiryDate}
                    onChange={(e) => updatePaymentInfo("expiryDate", e.target.value)}
                  />
                  <input
                    id="cvv"
                    name="cvv"
                    type="text"
                    placeholder="CVV"
                    className="w-full p-3 border border-gray-200 rounded-md bg-white"
                    value={formData.paymentInfo.cvv}
                    onChange={(e) => updatePaymentInfo("cvv", e.target.value)}
                  />
                </div>
                <select
                  id="country"
                  name="country"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.paymentInfo.country}
                  onChange={(e) => updatePaymentInfo("country", e.target.value)}
                >
                  <option value="">Select Country</option>
                  <option value="US">United States</option>
                  <option value="CA">Canada</option>
                  <option value="GB">United Kingdom</option>
                  <option value="PT">Portugal</option>
                </select>
                <input
                  id="billingAddress"
                  name="billingAddress"
                  type="text"
                  placeholder="Billing Address"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.paymentInfo.billingAddress}
                  onChange={(e) => updatePaymentInfo("billingAddress", e.target.value)}
                />
                <div className="grid grid-cols-2 gap-4">
                  <input
                    id="city"
                    name="city"
                    type="text"
                    placeholder="City"
                    className="w-full p-3 border border-gray-200 rounded-md bg-white"
                    value={formData.paymentInfo.city}
                    onChange={(e) => updatePaymentInfo("city", e.target.value)}
                  />
                  <input
                    id="postalCode"
                    name="postalCode"
                    type="text"
                    placeholder="Postal Code"
                    className="w-full p-3 border border-gray-200 rounded-md bg-white"
                    value={formData.paymentInfo.postalCode}
                    onChange={(e) => updatePaymentInfo("postalCode", e.target.value)}
                  />
                </div>
              </div>
            </div>
          )}

          {step === 5 && (
            <div className="space-y-6">
              <h1 className="text-4xl font-serif text-center">Final step</h1>
              <div className="flex justify-center">
                <HCaptcha
                  sitekey="9cfe3df0-d64c-4fc2-b66a-6f60d0bc5b92"
                  onVerify={handleCaptchaVerification}
                  aria-label="hCaptcha verification"
                  tabIndex={0}
                />
              </div>

              {error ? (
                <p className="text-center text-red-600" role="alert">{error}</p>
              ) : (
                <p className="text-center text-gray-600">We are looking forward to welcoming you!</p>
              )}
              <div className="flex items-center gap-2 justify-center mt-8">
                <input
                  type="checkbox"
                  id="subscribe"
                  name="subscribe"
                  checked={subscribeToUpdates}
                  onChange={(e) => setSubscribeToUpdates(e.target.checked)}
                  className="rounded border-gray-300"
                />
                <label htmlFor="subscribe" className="text-sm text-gray-600">
                  Subscribe to receive updates on special events
                </label>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Footer */}
      <div className="fixed bottom-0 left-0 right-0 bg-[#F0EFE9] border-t border-gray-100">
        <div className="max-w-md mx-auto px-4 py-6">
          <div className="flex items-center justify-center gap-4 relative">
            <button
              type="button"
              onClick={handleBack}
              className="absolute left-0 flex items-center justify-center text-gray-600"
              aria-label="Back"
            >
              <ChevronLeft className="w-8 h-8" />
            </button>
            <div className="flex flex-col items-center">
              <button
                type="button"
                disabled={(step === 5 && !hCaptchaToken) || isSubmitting}
                onClick={handleContinue}
                className={`px-12 py-3 border-2 border-black text-black rounded-full font-medium min-w-[160px] hover:bg-black hover:text-white transition-colors ${
                  isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {isSubmitting
                  ? "Submitting..."
                  : step === 5
                  ? "Complete"
                  : "Continue"}
              </button>
              <span className="text-sm text-gray-500 mt-2">{step}/5</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipForm;